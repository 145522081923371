// Here you can add other styles
#root {
  background-color: #333333;
}

.app-body {
  background-color: #f3f3f3;
}

body {
  background-color: #333333;
}

.btnPKYGreen {
  background-color: #545454;
  border-color: #57693f;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
}

.btnPKYGreen:hover {
  background-color: #808080;
  color: #ffffff;
}

.btnCancel {
  background-color: #ffffff;
  border-color: #c0c0c0;
  color: #000000;
  font-weight: bold;
}

.btnRemove {
  background-color: #ffffff;
  border-color: #c0c0c0;
  font-weight: bold;
  color: #000000;
}

.fa-left-bar {
  margin-right: 6px;
}

.disabled,
.disabled:hover,
.disabled td,
.disabled td:hover {
  background-color: #e0e0e0;
  color: #646464;
  cursor: default;
}

.app-header {
  background-color: #86b549;
  min-height: 60px;
}

#topBarContent {
  clear: both;
  width: 100%;
  max-width: 4000px;
  text-align: center;
}

//keskimenu
#topBarContent {
  overflow: hidden;
  background-color: #c8b07e;
  position: absolute;
  top: 90px;
  height: 30px;
}

#topBarContent a {
  display: inline-block;
  color: #ffffff;
  text-align: center;
  padding: 5px 20px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

#topBarContent a:hover {
  background-color: #303030;
  color: #ffffff;
  cursor: pointer;
}

#topBarContent a.active {
  background-color: #4caf50;
  color: white;
}

#topBarContent .icons {
  flex-direction: row;
}

#topBarContent .icons a {
  width: 20%;
}

.valittu {
  background-color: #3e3d42;
}

.header-fixed .app-body {
  margin-top: 105px;
}

.sidebar {
  background-color: #fff;
  border-right: 1px solid #c8ced3;
}

.sidebar .nav-link,
.sidebar .nav-title {
  color: #000000;
}

.sidebar .nav-dropdown.open .nav-link {
  background-color: #ffffff;
  color: #000000;
  border-left: 0;
  border-right: 1px solid #c8ced3;
}

.sidebar .nav-dropdown.open .nav-link.active {
  background-color: #cccccc;
  color: #000000;
  border-left: 0;
  border-right: 1px solid #c8ced3;
}

.sidebar .nav-link.active,
.nav-link.active,
.sidebar .nav-link.active,
.nav-link.active {
  background-color: #cccccc;
  color: #000000;
  border-right: 1px solid #c8ced3;
}

.sidebar .nav-link:hover {
  background-color: #f0f0f0;
  color: #000000;
  border-right: 1px solid #c8ced3;
}

.sidebar .nav-dropdown.open .nav-link:hover {
  background-color: #f0f0f0;
  color: #000000;
  border-right: 1px solid #c8ced3;
}

.sidebar .nav-dropdown-toggle {
  color: #000;
}

.buttonIcon {
  height: 20px;
  padding-bottom: 5px;
}

.form-control:disabled {
  background-color: #ffffff;
}

.container-fluid {
  margin-bottom: 10px;
}

.modalLoader .modal-content {
  border: none;
}

.modal-header {
  background-color: #86b549;
}

.dropdownHeader {
  background-color: #ffffff;
}

.modal-warning {
  background-color: rgb(255, 0, 0);
}

.dd-select {
  background-color: #ffffff;
  border-color: #cccccc;
}

.sidebar .nav-link .nav-icon {
  color: #000000;
}

.sidebar .nav-link:hover .nav-icon {
  color: #000000;
}

.sidebar .nav-link.active .nav-icon {
  color: #000000;
}

.batchCamera {
  color: #aaaaaa;
  -webkit-transition: color 1s ease-out;
  -moz-transition: color 1s ease-out;
  -o-transition: color 1s ease-out;
  transition: color 1s ease-out;
  transition: color 1s ease-out;
  transition: color 1s ease-out;
}

.batchCamera:hover {
  color: #000000;
}

@media (min-width: 786px) {
  .modal-dialog {
    max-width: 750px;
  }
}

#topBarContent .disabledMenu:hover,
.disabledMenu a:hover {
  background: #c8b07e;
  cursor: default;
  color: #cccccc;
}

#topBarContent .disabledMenu {
  color: #cccccc;
}

html > body > #root > div > div > div > div > ul > li > ul > li > a {
  padding-left: 30px;
}

.loginButton button {
  border: 2px solid #c8b07e;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  background-color: #ffffff;
}

.loginButton button:hover {
  color: #ffffff;
  background-color: #333333;
}

.modal-content {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-header {
  color: #ffffff;
  font-weight: bold;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-position: 50%;
}

.close {
  color: #ffffff;
}

.app-header {
  background-position: 50% 75%;
  padding-top: 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  padding-bottom: 10px;
  min-height: 90px;
}

.sidebar {
  margin-top: 20px;
}

.tunnit-overlay {
  display: none;
  text-align: right;
  color: #ffffff;
  padding-top: 10px;
  padding-right: 20px;
}

.tunnit-item:hover > .tunnit-overlay {
  position: absolute;
  top: 15px;
  left: 15px;
  bottom: 0px;
  right: 15px;
  background-color: #000;
  opacity: 0.3;
  z-index: 100;
  display: block;
  cursor: pointer;
}

.tunnit-item {
  position: relative;
}

.tunnit-overlay-hidden {
  display: none;
}

.DatePicker > .react-date-picker__wrapper,
.DatePicker > .react-datetime-picker__wrapper,
.DatePicker > .react-time-picker__wrapper {
  border-radius: 3px;
  border-color: #aaaaaa;
}

.DatePicker > .react-date-picker__wrapper button,
.DatePicker > .react-datetime-picker__wrapper button,
.DatePicker > .react-time-picker__wrapper button {
  background-color: #cccccc;
}

.DatePicker > .react-date-picker__wrapper > .react-date-picker__inputGroup {
  padding: 5px 2px;
}

.DatePicker > .react-date-picker__wrapper {
  border-color: #e4e7ea;
}

.TimePicker > .react-time-picker__wrapper {
  border-radius: 3px;
  border-color: #aaaaaa;
}

.TimePicker > .react-time-picker__wrapper button {
  background-color: #cccccc;
}

.react-time-picker > .react-time-picker__wrapper > .react-time-picker__inputGroup {
  padding: 5px 2px;
}

.react-time-picker > .react-time-picker__wrapper {
  border-color: #e4e7ea;
}

.trash:hover {
  color: red;
  cursor: pointer;
}

.trash {
  -webkit-transition: color 0.5s; /* Safari 3.1 to 6.0 */
  transition: color 0.5s;
}

.progress-grey:hover {
  cursor: pointer;
  background-color: #aaaaaa;
}

.progress-grey,
.progress-grey-disabled {
  background-color: #cccccc;
}

.progress-grey {
  -webkit-transition: background-color 0.5s; /* Safari 3.1 to 6.0 */
  transition: background-color 0.5s;
}

.app-header > .navbar-brand {
  width: 250px;
}

.subtask-dd > div {
  padding: 0;
  border-color: #cccccc;
}

tr[class^="row-"] {
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
}

.progress-green-1 {
  background-color: #4caf50;
}

.progress-green-2 {
  background-color: #208a23;
}

// .tehtavat-tooltip {
// }

.tehtavat-tooltip-inner {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.rdt {
  position: relative;
}

.rdt .rdtPicker {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  margin-top: -20px;
  visibility: hidden;
  display: block;
  opacity: 0;
}
.rdt.rdtOpen .rdtPicker {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px;
}
.rdtPicker:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: -5px;
  left: 10px;
  right: auto;
  color: #ffffff;
  border-bottom: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}
.rdtPicker:after {
  border-bottom: 0.4em solid #ffffff;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  top: -5px;
  left: 10px;
}

.rdtPicker {
  display: block;
  top: 40px;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #ffffff !important;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
}
.rdtDay.rdtToday.rdtActive,
.rdtDay.rdtActive,
.rdtDay.rdtActive:hover {
  background-color: #c8b07e !important;
  color: #ffffff;
}

.rdtDays tr .dow {
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px;
}

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #dddddd;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px;
}

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #c8b07e;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%;
}

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}
.rdtCounter .rdtCount {
  padding: 7px;
  height: 40px;
  border: 1px solid transparent;
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  border-radius: 50%;
}

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #dddddd;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979;
}

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px;
}

.rdtMonths,
.rdtYears {
  padding-bottom: 10px;
}
.rdtMonths .rdtMonth,
.rdtMonths .rdtYear,
.rdtYears .rdtMonth,
.rdtYears .rdtYear {
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.rdtMonths .rdtMonth.rdtActive,
.rdtMonths .rdtYear.rdtActive,
.rdtYears .rdtMonth.rdtActive,
.rdtYears .rdtYear.rdtActive {
  background-color: #c8b07e !important;
  color: #ffffff;
}

.react-tagsinput {
  display: inline-block;
  padding: 4px 6px;
  max-width: 100%;
  line-height: 22px;
}

.react-tagsinput-tag {
  transition: all 0.17s linear;
  cursor: pointer;
  margin: 5px 3px 5px 0;
  position: relative;
  padding: 4px 8px 3px 0.8em;
  border-radius: 12px;
  color: #fff;
  font-size: 0.75em;
  font-weight: 500;
  display: inline-block;
  line-height: 1.5em;
  text-transform: uppercase;
  border: 1px solid;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  font-family: "nucleo-icons";
  content: "\ea4f";
  padding: 0px 2px;
}

.react-tagsinput-tag a {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0px;
  opacity: 0;
  background-color: transparent;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}

.react-tagsinput .react-tagsinput-tag {
  -webkit-transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s;
  border-color: #66615b;
  background-color: #66615b;
}
.react-tagsinput .react-tagsinput-tag:hover {
  padding-right: 22px;
}
.react-tagsinput .react-tagsinput-tag:hover a {
  opacity: 1;
  padding-right: 4px;
}
.react-tagsinput .react-tagsinput-tag.primary {
  border-color: #c8b07e;
  background-color: #c8b07e;
}
.react-tagsinput .react-tagsinput-tag.info {
  border-color: #51bcda;
  background-color: #51bcda;
}
.react-tagsinput .react-tagsinput-tag.success {
  border-color: #6bd098;
  background-color: #6bd098;
}
.react-tagsinput .react-tagsinput-tag.warning {
  border-color: #fbc658;
  background-color: #fbc658;
}
.react-tagsinput .react-tagsinput-tag.danger {
  border-color: #ef8157;
  background-color: #ef8157;
}
.react-tagsinput .react-tagsinput-tag.neutral {
  border-color: #ffffff;
  background-color: #ffffff;
  color: inherit;
}
